define("discourse/plugins/discourse-events/discourse/connectors/composer-fields/composer-controls-event", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.events_enabled;
    },
    setupComponent(_, component) {
      const controller = (0, _application.getOwner)(this).lookup("controller:composer");
      component.set("eventValidation", controller.get("eventValidation"));
      controller.addObserver("eventValidation", () => {
        if (this._state === "destroying") {
          return;
        }
        component.set("eventValidation", controller.get("eventValidation"));
      });
    }
  };
});