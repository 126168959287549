define("discourse/plugins/discourse-events/discourse/connectors/admin-menu/events-nav-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.siteSettings.events_enabled;
    }
  };
});